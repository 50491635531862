// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-custom {
  height: 38px;
  background-color: #f8f8f8;
  color: #393939;
  font-size: 15px;
  border: none;
  outline: none;
  padding: 0 10px;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/app/log/log.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,yBAAA;EACA,cAAA;EACA,eAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;AACJ","sourcesContent":[".input-custom {\n    height: 38px;\n    background-color: #f8f8f8;\n    color: #393939;\n    font-size: 15px;\n    border: none;\n    outline: none;\n    padding: 0 10px;\n    border-radius: 4px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
