// src/app/store/effects/auth.effects.ts
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import * as AuthActions from "../actions/auth.actions";
import { ColaboradorService } from "../service/colaborador.service";
import { KeycloakService } from "keycloak-angular";
import { GovBrService } from "../service/govbr.service";
import { environment } from "src/environments/environment";
import { SessionStorageService } from "../service/sessionstorage.service";

@Injectable()
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private colaboradorService: ColaboradorService,
    private keycloakService: KeycloakService,
    private govbrService: GovBrService,
    private sessionStorageService: SessionStorageService
  ) { }

  checkWritePermission$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.checkWritePermission),
      mergeMap(({ idPessoaRepresentada }) =>
        this.colaboradorService
          .checarPerfisEscritaExistem(idPessoaRepresentada)
          .pipe(
            map((hasPermission) =>
              AuthActions.checkWritePermissionSuccess({ hasPermission })
            ),
            catchError((error) =>
              of(AuthActions.checkWritePermissionFailure({ error }))
            )
          )
      )
    )
  );

  // New logout effect
  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logout),
        tap(() => {
          // Clear local storage or any other client-side storage
          localStorage.clear(); // Clears all local storage
          this.sessionStorageService.clear(); // Clears all session storage
          
          // Clear Keycloak token
          this.keycloakService.logout();
        })
      ),
    { dispatch: false }
  );

  loadGovBrAccessToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.loadGovBrAccessToken),
      mergeMap(() =>
        this.govbrService.getGovBrAccessToken().pipe(
          map((token) => AuthActions.loadGovBrAccessTokenSuccess({ token })),
          catchError((error) => of(AuthActions.loadGovBrAccessTokenFailure({ error })))
        )
      )
    )
  );
}
