import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { Store, select } from "@ngrx/store";
import { Observable, Subject, Subscription } from "rxjs";
import { take, takeUntil } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";

import { AppState } from "src/app/interfaces/app-state.interface";
import { Constants } from "src/app/util/constants";
import { Functions } from "src/app/util/functions";
import { ToastrFunctions } from "src/app/util/toastr.functions";
import { Perfil } from "src/app/model/perfil.model";
import { PessoaJuridica } from "src/app/model/pessoajuridica.model";

import { loadCompany, loadAllCompanies } from "src/app/actions/company.action";
import {
  setSelectedProfile,
  logout,
} from "src/app/actions/auth.actions";
import { selectAllCompanies } from "src/app/selectors/company.selector";
import { selectPerfis } from "src/app/selectors/auth.selectors";

import { ResetService } from "src/app/service/reset.service";
import { HomeService } from "src/app/service/home.service";
import { EnableOrgService } from "src/app/service/enableorg.service";
import { EnableTxtNoPessoaFisicaService } from "src/app/service/enabletxtnopessoafisica.service";
import { loadAllEmployees } from "src/app/actions/employee.action";
import { UsuarioLogado } from "src/app/model/usuariologado.model";
import { SessionStorageService } from "src/app/service/sessionstorage.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit {
  allCompanies$!: Observable<PessoaJuridica[]>;
  selectedIdPessoaJuridica = new FormControl("");
  unsubscribe$ = new Subject<void>();
  username!: string;
  perfis: Perfil[] = [];
  isDropdownOpen = false;
  isShowSelectEmpresa = false;
  isHomePage = false;
  selectedPerfil = "";
  private resetSubscription!: Subscription;
    
  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private router: Router,
    private resetService: ResetService,
    private homeService: HomeService,
    private enableOrgService: EnableOrgService,
    private enableTxtNoPessoaFisicaService: EnableTxtNoPessoaFisicaService,
    private sessionStorageService: SessionStorageService 
  ) {}

  ngOnInit(): void {
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    this.setupCompanySelection();
    this.initializeUser(usuarioLogado);
    this.subscribeToResetEvents();
    this.subscribeToRouterEvents();
  }

  ngAfterViewInit() {
    this.loadCompanyDataIfNecessary();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.resetSubscription?.unsubscribe();
  }

  onToggleChangePerfil(perfil: string) {
    this.updateSelectedProfile(perfil);
  }

  isAdminPerfil(perfil: string): boolean {
    return [
      Constants.PERFIL_GESTOR_ANVISA,
      Constants.PERFIL_ADMINISTRADOR,
      Constants.PERFIL_GESTOR_ANVISA_CONSULTA,
    ].includes(perfil);
  }

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  logout() {
    this.store.dispatch(logout());
  }

  private initializeUser(usuarioLogado: UsuarioLogado) {
    this.username = usuarioLogado.givenName;
    this.fetchPerfis();
    this.setInitialPerfil(usuarioLogado);
  }

  private setInitialPerfil(usuarioLogado: UsuarioLogado) {
    const selectedProfile = usuarioLogado.selectedProfile;
    if (selectedProfile) {
      this.updateSelectedProfile(selectedProfile);
    } else {
      this.setPerfilBasedOnPriority(usuarioLogado);
    }
  }

  private setupCompanySelection() {
    this.allCompanies$ = this.store.pipe(select(selectAllCompanies));
        
    this.allCompanies$.pipe(takeUntil(this.unsubscribe$)).subscribe((companies) => {
      this.handleCompanySelection(companies);
    });

    this.selectedIdPessoaJuridica.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((idPessoaJuridica) => {
        if (idPessoaJuridica) {
          this.loadCompanyData(idPessoaJuridica);
        }
      });
  }

  private handleCompanySelection(companies: PessoaJuridica[]) {
    const isNotAdmin = !this.isAdminPerfil(this.selectedPerfil);
    const savedIdPessoaJuridica = this.sessionStorageService.getItem("selectedIdPessoaJuridica");
  
    let newIdPessoaJuridica: string | null = null;
  
    if (companies.length === 1 && isNotAdmin) {
      newIdPessoaJuridica = companies[0].id ?? "";
    } else if (savedIdPessoaJuridica && isNotAdmin) {
      newIdPessoaJuridica = savedIdPessoaJuridica;
    }
  
    // Ensure newIdPessoaJuridica is not null and different from the current value before updating the FormControl
    if (newIdPessoaJuridica && newIdPessoaJuridica !== this.selectedIdPessoaJuridica.value) {
      this.selectedIdPessoaJuridica.setValue(newIdPessoaJuridica);
    }
    
    this.isShowSelectEmpresa = Boolean(companies.length) && !this.isHomePage && isNotAdmin;
  }  

  private loadCompanyDataIfNecessary() {
    const isNotAdmin = !this.isAdminPerfil(this.selectedPerfil);
    if (this.selectedIdPessoaJuridica.value && isNotAdmin) {
      this.loadCompanyData(this.selectedIdPessoaJuridica.value);
    }
  }

  private loadCompanyData(idPessoaJuridica: string) {
    this.store.dispatch(loadCompany({ idPessoaJuridica }));
    this.sessionStorageService.setItem("selectedIdPessoaJuridica", idPessoaJuridica);
    this.store.dispatch(loadAllEmployees({ idPessoaJuridica }));
  }

  private subscribeToResetEvents() {
    this.resetSubscription = this.resetService.reset$.subscribe(() => {
      this.resetAll();
    });
  }

  private subscribeToRouterEvents() {
    this.router.events.pipe(takeUntil(this.unsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isHomePage = event.urlAfterRedirects === Constants.HOME_PAGE;
        this.allCompanies$.pipe(takeUntil(this.unsubscribe$)).subscribe((companies) => {
          this.handleCompanySelection(companies);
        });       
      }
    });
  }

  private fetchPerfis() {
    this.store.pipe(select(selectPerfis), takeUntil(this.unsubscribe$)).subscribe({
      next: (perfis) => this.handlePerfisFetch(perfis),
      error: () => {
        ToastrFunctions.showError(this.toastr, "Algum erro ocorreu ao pesquisar Perfis.");
      },
    });
  }

  private handlePerfisFetch(perfis: Perfil[]) {
    if (perfis?.length) {
      this.perfis = perfis;
      this.setSelectedPerfil();
    }
  }

  private setSelectedPerfil() {
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    if (!usuarioLogado.selectedProfile) {
      this.setPerfilBasedOnPriority(usuarioLogado);
    } else {
      this.selectedPerfil = usuarioLogado.selectedProfile;
    }
  }

  private setPerfilBasedOnPriority(usuarioLogado: UsuarioLogado | null) {
    const perfilPriorities = [
      Constants.PERFIL_GESTOR_ANVISA,
      Constants.PERFIL_RESPONSAVEL_LEGAL,
      Constants.PERFIL_GESTOR_CADASTROS,
      Constants.PERFIL_GESTOR_ANVISA_CONSULTA,
      Constants.PERFIL_ADMINISTRADOR,
    ];

    for (const priority of perfilPriorities) {
      const perfil = this.perfis.find((p) => p.noPerfil === priority);
      if (perfil) {
        this.updateSelectedProfile(perfil.noPerfil);
        if (usuarioLogado) {
          usuarioLogado.selectedProfile = this.selectedPerfil;
          Functions.setUsuarioLogadoIntoSession(usuarioLogado);
        }        
        break;
      }
    }
  }

  private updateSelectedProfile(perfil: string) {
    this.selectedPerfil = perfil;
    const usuarioLogado = Functions.getUsuarioLogadoFromSession();
    
    usuarioLogado.selectedProfile = perfil;
    Functions.setUsuarioLogadoIntoSession(usuarioLogado);
    
    this.store.dispatch(setSelectedProfile({ profile: perfil }));

    this.store.dispatch(loadAllCompanies());
    this.allCompanies$.pipe(
      take(1) // Take the first value and then complete
    ).subscribe(companies => {
      this.handleCompanySelection(companies);
    });

    if (this.isHomePage) {
        this.resetService.triggerReset();
    }

    this.homeService.triggerHome();
    this.enableOrgService.triggerEnableOrg();
    this.enableTxtNoPessoaFisicaService.triggerEnableTxtNoPessoaFisica();
  }

  private resetAll() {
    this.selectedIdPessoaJuridica.setValue("");
    this.sessionStorageService.removeItem("selectedIdPessoaJuridica");
    this.store.dispatch(loadAllCompanies());
  }
}

