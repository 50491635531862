import { createReducer, on } from '@ngrx/store';
import { CompanyState } from '../interfaces/company-state.interface';
import * as CompanyActions from '../actions/company.action';
import { PessoaJuridicaSerpro } from '../model/pessoajuridicaserpro';

export const initialState: CompanyState = {
  selectedCompany: undefined,
  allCompanies: [],
  selectedCompanyPorte: undefined,
  empresas: [],
  pessoaJuridicaSerpro: null,
  isLoaded: {
    loadCompany: true,
    loadAllCompanies: true,
    consultaCNES: true
  },
  error: null,
  empresasLoaded: false,
  companies: null,
  timestamp: null
};

export const companyReducer = createReducer(
  initialState,

  on(CompanyActions.loadCompanySuccess, (state, { company }) => ({
    ...state,
    selectedCompany: company,
    isLoaded: { ...state.isLoaded, loadCompany: true },
    error: null // Clear any previous errors on successful load
  })),
  on(CompanyActions.loadCompanyFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadCompany: true },
    error // Capture error to be potentially displayed or logged
  })),

  on(CompanyActions.loadAllCompaniesSuccess, (state, { companies }) => ({
    ...state,
    allCompanies: companies,
    isLoaded: { ...state.isLoaded, loadAllCompanies: true },
    error: null, // Clear any previous errors on successful load
    timestamp: new Date() // Set the current timestamp when data is successfully loaded
  })),
  on(CompanyActions.loadAllCompaniesFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, loadAllCompanies: true },
    error: null
  })),

  on(CompanyActions.loadEmpresasSuccess, (state, { empresas }, ) => ({
    ...state,
    empresas,
    empresasLoaded: true,
    error: null
  })),
  on(CompanyActions.loadEmpresasFailure, (state, { error }) => ({
    ...state,
    empresasLoaded: false,
    error
  })),

  on(CompanyActions.updateEmpresasSuccess, (state) => ({
    ...state,
    error: null
  })),
  on(CompanyActions.updateEmpresasFailure, (state, { error }) => ({
    ...state,
    error
  })),
  on(CompanyActions.consultaCNPJSuccess, (state, { data }) => {
    const updatedData = new PessoaJuridicaSerpro(data);
  
    return {
      ...state,
      pessoaJuridicaSerpro: updatedData,
      error: null
    };
  }),
  on(CompanyActions.consultaCNPJFailure, (state, { error }) => ({
    ...state,
    error
  })),
  
  on(CompanyActions.consultaPorteSuccess, (state, { porte }) => ({
    ...state,
    selectedCompanyPorte: porte,
    isLoaded: { ...state.isLoaded, consultaPorte: true },
    error: null // Clear any previous errors on successful load
  })),
  on(CompanyActions.consultaCNPJFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, consultaPorte: true },
    error // Capture error to be potentially displayed or logged
  })),

  on(CompanyActions.clearPorte, (state) => ({
    ...state,
    selectedCompanyPorte: undefined
  })),

  on(CompanyActions.clearCompany, (state) => ({
    ...state,
    selectedCompany: undefined
  })),

  on(CompanyActions.clearSerproCompany, (state) => ({
    ...state,
    pessoaJuridicaSerpro: null
  })),

  on(CompanyActions.clearSerproCompany, (state) => ({
    ...state,
    pessoaJuridicaSerpro: null
  })),

  on(CompanyActions.consultaCNES, (state) => ({
    ...state,
    isLoaded: { ...state.isLoaded, consultaCNES: false },
    error: null, // Reset error state on a new load
  })),

  on(CompanyActions.consultaCNESSuccess, (state, { companies }) => ({
    ...state,
    companies: companies,
    isLoaded: { ...state.isLoaded, consultaCNES: true },
    error: null
  })),
  
  on(CompanyActions.consultaCNESFailure, (state, { error }) => ({
    ...state,
    isLoaded: { ...state.isLoaded, consultaCNES: true },
    error
  })),

  on(CompanyActions.clearCNES, (state) => ({
    ...state,
    companies: null
  })),

);
