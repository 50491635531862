// src/environments/environment.develop.ts
export const environment = {
	production: false,
  API: 'https://cadastro-anvisa-backend.dev.apps.anvisa.gov.br',
  AUTH_SERVER: 'https://acesso.dev.apps.anvisa.gov.br/auth/',
  REALM: 'anvisa',
  CLIENT_ID: 'front-anvisa',
  IS_LOCAL: false,
  websocketUrl: 'wss://cadastro-anv-notif.dev.apps.anvisa.gov.br/ws',
  GOVBR_LOGOUT_URL: 'https://sso.staging.acesso.gov.br/logout?post_logout_redirect_uri=https://cadastro-anvisa-frontend.tst.apps.anvisa.gov.br',
  CRYPTO_SECRET_KEY: 'aaef578dd2067a1b571afa972ff736576ea40a5bbeeae12d047ca0d904a3cd8e'
};
