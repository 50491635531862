// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dialog-content {
  overflow-y: auto;
  max-height: calc(100vh - 210px); /* Adjust height */
}

.full-width {
  width: 100%;
}

.add-btn {
  margin-bottom: 20px;
}

.flex-container {
  display: flex;
  align-items: center; /* Vertical alignment */
}

.delete-btn {
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/integrador/add-integration-dialog/add-integration-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,+BAAA,EAAA,kBAAA;AACJ;;AAEE;EACE,WAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,aAAA;EACA,mBAAA,EAAA,uBAAA;AACJ;;AAEE;EACE,mBAAA;AACJ","sourcesContent":[".dialog-content {\n    overflow-y: auto;\n    max-height: calc(100vh - 210px); /* Adjust height */\n  }\n\n  .full-width {\n    width: 100%;\n  }\n\n  .add-btn {\n    margin-bottom: 20px;\n  }\n\n  .flex-container {\n    display: flex;\n    align-items: center; /* Vertical alignment */\n  }\n  \n  .delete-btn {\n    margin-bottom: 15px;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
