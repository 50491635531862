// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-list {
  list-style-type: none; /* Removes the default list style */
  padding-left: 0; /* Removes the default padding */
  margin: 0; /* Removes the default margin */
}

.custom-list li {
  margin-bottom: 5px; /* Adds space between list items */
}`, "",{"version":3,"sources":["webpack://./src/app/integrador/view-integration-dialog/view-integration-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,qBAAA,EAAA,mCAAA;EACA,eAAA,EAAA,gCAAA;EACA,SAAA,EAAA,+BAAA;AACJ;;AAEE;EACE,kBAAA,EAAA,kCAAA;AACJ","sourcesContent":[".custom-list {\n    list-style-type: none; /* Removes the default list style */\n    padding-left: 0; /* Removes the default padding */\n    margin: 0; /* Removes the default margin */\n  }\n\n  .custom-list li {\n    margin-bottom: 5px; /* Adds space between list items */\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
