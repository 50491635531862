import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import { Observable, Subject, interval, switchMap, takeUntil, tap, takeWhile } from "rxjs";
import { clearCNES, consultaCNES } from "src/app/actions/company.action";
import { AppState } from "src/app/interfaces/app-state.interface";
import { PessoaJuridica } from "src/app/model/pessoajuridica.model";
import { PessoasJuridicas } from "src/app/model/pessoasjuridicas.model";
import { selectCNESCompanies } from "src/app/selectors/company.selector";
import { Functions } from "src/app/util/functions";
import { ToastrFunctions } from "src/app/util/toastr.functions";

@Component({
  selector: "app-dialog-search-instituicao",
  templateUrl: "./dialog-search-instituicao.component.html",
})
export class DialogSearchInstituicaoComponent implements OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  
  pageSizes = [20, 50, 100];
  totalElements = 0;
  displayedColumns = ["id", "cnpj", "cnes", "razaoSocial"];
  dataSource = new MatTableDataSource<PessoaJuridica>();
  cnes = "";
  companies$!: Observable<PessoasJuridicas | null>;
  isLoading = false;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private store: Store<AppState>,
    private toastr: ToastrService,
    private dialogRef: MatDialogRef<DialogSearchInstituicaoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any
  ) {
    this.cnes = this.data.cnes || "";
  }

  ngOnInit(): void {
    this.companies$ = this.store.pipe(select(selectCNESCompanies));
  
    this.companies$
      .pipe(
        takeUntil(this.destroy$),
        tap((companies) => {
          if (companies) {
            this.dataSource.data = companies.content;
            this.totalElements = companies.totalElements;
  
            // Reset loading state only if totalElements > 0
            if (this.totalElements > 0) {
              this.isLoading = false;
            }
          }
        })
      )
      .subscribe();
  }
  

  findInstituicoesByCnes(page = 0, size = 20): void {
    if (!this.cnes.trim()) {
      ToastrFunctions.showWarning(this.toastr, "Preencha o CNES.");
      return;
    }

    this.isLoading = true; // Start loading state
    let attempts = 0;
    let messageShown = false;

    // Initial dispatch before starting retries
    this.dispatchConsultaCNES(page, size);

    interval(10000)
      .pipe(
        takeWhile(() => this.dataSource.data.length === 0 && attempts < 4),
        tap(() => attempts++),
        switchMap(() => this.dispatchConsultaCNES(page, size)),
        takeUntil(this.destroy$)
      )
      .subscribe((companies) => {
        if (companies && companies.content && companies.content.length > 0) {
          this.dataSource.data = companies.content;
          this.totalElements = companies.totalElements;
          this.isLoading = false; // Reset loading state if content is returned
        } else if (attempts === 4 && !messageShown) {
          // Show the message only once after 4 attempts and no content
          messageShown = true;
          this.showNoResultsMessage();
          this.isLoading = false; // Reset loading state after max retries
        }
      });
  }

  private dispatchConsultaCNES(page: number, size: number): Observable<PessoasJuridicas | null> {
    this.store.dispatch(
      consultaCNES({
        cnes: this.cnes,
        page: page,
        size: size,
      })
    );
    return this.companies$;
  }

  private resetDataSource(): void {
    this.dataSource.data = [];
    this.totalElements = 0;
  }

  private showNoResultsMessage(): void {
    ToastrFunctions.showInfo(
      this.toastr,
      "Nenhum resultado encontrado para a consulta. O CNES informado pode não existir ou estar com status 'Desativado' ou 'Excluído'."
    );
  }

  selecionaInstituicao(pessoaJuridica: PessoaJuridica): void {
    this.dialogRef.close(pessoaJuridica);
    this.store.dispatch(clearCNES());
  }

  nextPage(event: PageEvent): void {
    this.findInstituicoesByCnes(event.pageIndex, event.pageSize);
  }

  reset(): void {
    this.cnes = "";
    this.resetDataSource();
    this.store.dispatch(clearCNES());
  }

  formatCnpj(cnpj: string): string {
    return Functions.formatCnpj(cnpj);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}