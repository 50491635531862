import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FlatTreeControl } from "@angular/cdk/tree";
import {
  MatTreeFlatDataSource,
  MatTreeFlattener,
} from "@angular/material/tree";
import { Store, select } from "@ngrx/store";
import { ToastrService } from "ngx-toastr";
import {
  BehaviorSubject,
  EMPTY,
  Observable,
  Subject,
  Subscription,
  catchError,
  concatMap,
  filter,
  forkJoin,
  from,
  map,
  mergeMap,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
  throwError,
  timeout,
  toArray,
} from "rxjs";

import {
  selectAllSelected,
  selectIsUpdatedEmployee,
  selectEmployees,
} from "src/app/selectors/employee.selector";
import {
  selectAllSistemaByUsuarioRepresentanteId,
  selectAllSistemas,
} from "src/app/selectors/sistema.selector";
import { Sistema } from "src/app/model/sistema.model";
import {
  clearPerfilState,
  loadColaboradorProfilesByNode,
  loadPerfisBySistema,
} from "src/app/actions/perfil.actions";
import { ToastrFunctions } from "src/app/util/toastr.functions";
import { Constants } from "src/app/util/constants";
import { PessoaJuridica } from "src/app/model/pessoajuridica.model";
import {
  clearEditEmployees,
  deletePermissionsByUsuarioRepresentanteId,
  editEmployees,
  insertSystemProfilesBatch,
  toggleUserBlockBatchStatus,
  updateExpirationDateBatch,
} from "src/app/actions/employee.action";
import {
  selectAllPerfis,
  selectColaboradoresProfilesByNodeId,
} from "src/app/selectors/perfil.selector";
import { Perfil } from "src/app/model/perfil.model";
import {
  selectAllCompanies,
  selectCompany,
} from "src/app/selectors/company.selector";
import { MatDialog, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  clearSistemaState,
  loadSistemaByUsuarioRepresentanteId,
  loadSistemas,
} from "src/app/actions/sistema.actions";
import { DynamicNode } from "src/app/model/dynamicnode";
import { DynamicFlatNode } from "src/app/model/dynamicflatnode";
import { DialogComponent } from "src/app/components/dialog/dialog.component";
import { ColaboradorCustom } from "src/app/model/colaboradorcustom";
import { AppState } from "src/app/interfaces/app-state.interface";
import { selectProfile } from "src/app/selectors/auth.selectors";
import { MatSlideToggleChange } from "@angular/material/slide-toggle";

@Component({
  selector: "app-dialog-colab-atr-perfil-lote",
  templateUrl: "./dialog-colab-atr-perfil-lote.component.html",
  styleUrls: ["./dialog-colab-atr-perfil-lote.component.scss"],
})
export class DialogColabAtrPerfilLoteComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();

  sistemas$?: Observable<Sistema[]>;
  perfis$?: Observable<Perfil[]>;
  allCompanies$!: Observable<PessoaJuridica[]>;
  allSistemasByUsuarioRepresentanteId$!: Observable<{
    [usuarioRepresentanteId: string]: Sistema[];
  }>;
  selectEmployees$?: Observable<ColaboradorCustom[]>;
  isUpdated$!: Observable<boolean>;
  profileSelected$!: Observable<string | null>;
  selectedCompany$: Observable<PessoaJuridica | undefined>;

  displayedColumns: string[] = [
    "id",
    "nome",
    "ativo",
    "data_expiracao",
    "sistemas_perfis",
  ];

  // Exposing Constants to the template
  Constants = Constants;

  transformer = (node: DynamicNode, level: number): DynamicFlatNode => {
    return new DynamicFlatNode(
      node.id,
      node.item,
      level,
      node.hasChildren,
      node.isLoading
    );
  };

  hasChild = (_: number, node: DynamicFlatNode) => node.expandable;

  treeControls: {
    [usuarioRepresentanteId: string]: FlatTreeControl<
      DynamicFlatNode,
      DynamicFlatNode
    >;
  } = {};

  dataSourceTable: { [usuarioRepresentanteId: string]: DynamicNode[] } = {};
  treeDataSources: Map<
    string,
    BehaviorSubject<
      MatTreeFlatDataSource<DynamicNode, DynamicFlatNode, DynamicFlatNode>
    >
  > = new Map<
    string,
    BehaviorSubject<
      MatTreeFlatDataSource<DynamicNode, DynamicFlatNode, DynamicFlatNode>
    >
  >();

  isGestorCadastroSelected = false;
  cnpjs: PessoaJuridica[] = [];

  selectedSistemaId = "";
  selectedPerfilId = "";

  pessoaJuridicaId!: string;

  private dataSubscription: Subscription = new Subscription();

  dtExpiracao!: Date | null;
  active = true;

  isPerfilGestorAnvisa = false;

  constructor(
    public activatedRoute: ActivatedRoute,
    public toastr: ToastrService,
    public router: Router,
    private store: Store<AppState>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.sistemas$ = this.store.pipe(select(selectAllSistemas));
    this.perfis$ = this.store.pipe(select(selectAllPerfis));
    this.allCompanies$ = this.store.pipe(select(selectAllCompanies));
    this.selectEmployees$ = this.store.select(selectEmployees);
    this.isUpdated$ = this.store.select(selectIsUpdatedEmployee);
    this.profileSelected$ = this.store.select(selectProfile);
    this.selectedCompany$ = this.store.pipe(select(selectCompany));
  }

  ngOnInit(): void {
    this.pessoaJuridicaId = this.data.pessoaJuridicaId;
    this.isPerfilGestorAnvisa = this.data.isPerfilGestorAnvisa;

    this.store.dispatch(clearPerfilState());
    this.store.dispatch(clearSistemaState());
    this.store.dispatch(clearEditEmployees());

    this.store.dispatch(loadSistemas());

    this.initDataLoad();
  }

  private initDataLoad(): void {
    this.dataSubscription?.unsubscribe(); // Unsubscribe from any previous subscription to prevent duplicates

    this.dataSubscription = this.store
      .pipe(
        select(selectAllSelected),
        takeUntil(this.destroy$),
        filter((employees) => employees.length > 0),
        concatMap((employees) => {
          // Determine if active should be true or false
          this.active = employees.every(
            (employee) => employee.stBloqueado === "S"
          )
            ? false
            : true;

          // Dispatch the action with active status
          this.store.dispatch(
            editEmployees({
              employees,
              isPerfilGestorAnvisa: this.isPerfilGestorAnvisa,
            })
          );

          return from(employees).pipe(
            mergeMap((employee) => this.loadInitialData(employee)),
            toArray(), // Collect all results from the mergeMap into an array
            takeUntil(this.destroy$) // Ensures subscription is cleaned up
          );
        }),
        catchError((error) => {
          console.error("Failed to load initial data:", error);
          return EMPTY;
        })
      )
      .subscribe();
  }

  loadInitialData(colaboradorCustom: ColaboradorCustom): Observable<any> {
    return this.store
      .select(
        selectAllSistemaByUsuarioRepresentanteId(
          colaboradorCustom.idUsuarioRepresentante
        )
      )
      .pipe(
        takeUntil(this.destroy$),
        concatMap((sistemas) =>
          this.processSistemas(sistemas, colaboradorCustom)
        ),
        catchError((error) =>
          this.handleLoadError(error, "Failed to load initial data")
        )
      );
  }

  processSistemas(
    sistemas: Sistema[],
    colaboradorCustom: ColaboradorCustom
  ): Observable<any> {
    if (!sistemas) {
      this.store.dispatch(
        loadSistemaByUsuarioRepresentanteId({
          idUsuarioRepresentante: colaboradorCustom.idUsuarioRepresentante,
        })
      );
      return this.waitForSistemas(colaboradorCustom.idUsuarioRepresentante);
    }
    return this.processSistemaChildren(sistemas, colaboradorCustom);
  }

  private processSistemaChildren(
    sistemas: Sistema[],
    colaboradorCustom: ColaboradorCustom
  ): Observable<any> {
    const childrenObservables = sistemas.map((sistema) =>
      this.createChildren(
        colaboradorCustom.idUsuarioRepresentante,
        sistema.id
      ).pipe(
        map(
          (children) =>
            new DynamicNode(
              sistema.id,
              sistema.sgSistema,
              true,
              false,
              children
            )
        )
      )
    );
    return forkJoin(childrenObservables).pipe(
      tap((dynamicNodes) =>
        this.handleDynamicNodes(dynamicNodes, colaboradorCustom)
      ),
      catchError((error) => {
        console.error("Error in forkJoin:", error);
        return of([]);
      })
    );
  }

  private handleDynamicNodes(
    dynamicNodes: DynamicNode[],
    colaboradorCustom: ColaboradorCustom
  ): void {
    if (dynamicNodes) {
      const result = {
        [colaboradorCustom.idUsuarioRepresentante]: dynamicNodes,
      };
      this.handleResult(result);
    } else {
      console.log(
        "No result for one of the employees, likely due to an error."
      );
    }
  }

  private handleLoadError(error: any, message: string): Observable<{}> {
    console.error(message, error);
    ToastrFunctions.showError(this.toastr, message);
    return of({});
  }

  private createChildren(
    idUsuarioRepresentante: string,
    id: string
  ): Observable<DynamicNode[]> {
    return this.store
      .select(selectColaboradoresProfilesByNodeId(idUsuarioRepresentante, id))
      .pipe(
        takeUntil(this.destroy$),
        take(1), // Ensure only one value is taken from the observable
        switchMap((profiles) => {
          // If no profiles, dispatch action and wait for profiles
          if (!profiles) {
            this.store.dispatch(
              loadColaboradorProfilesByNode({
                coSistema: id,
                idUsuarioRepresentante: idUsuarioRepresentante,
              })
            );
            return this.waitForProfiles(idUsuarioRepresentante, id);
          }
          // If profiles exist, pass them through
          return of(profiles);
        }),
        map((profiles) =>
          profiles.map(
            (profile) =>
              new DynamicNode(profile.id, profile.noPerfil, false, false)
          )
        ),
        catchError((error) => {
          console.error("Error loading children data", error);
          ToastrFunctions.showError(
            this.toastr,
            "Failed to load data for the selected node."
          );
          return of([]);
        }),
        takeUntil(this.destroy$) // Ensures cleanup if the component is destroyed before completion
      );
  }

  private waitForSistemas(
    idUsuarioRepresentante: string
  ): Observable<Sistema[]> {
    return this.store
      .select(selectAllSistemaByUsuarioRepresentanteId(idUsuarioRepresentante))
      .pipe(
        takeUntil(this.destroy$),
        filter((sistemas) => !!sistemas),
        timeout(5000), // Fails the observable stream if no value is emitted within 5000 milliseconds
        catchError((error) => {
          console.error("Timeout or error while waiting for sistemas", error);
          ToastrFunctions.showError(
            this.toastr,
            "Timeout or error while waiting for systems."
          );
          return throwError(() => error); // Re-throw the error
        }),
        take(1), // Ensure the observable completes after emitting its value or encountering an error
        takeUntil(this.destroy$) // Added as an extra precaution
      );
  }

  private waitForProfiles(
    idUsuarioRepresentante: string,
    id: string
  ): Observable<Perfil[]> {
    return this.store
      .select(selectColaboradoresProfilesByNodeId(idUsuarioRepresentante, id))
      .pipe(
        takeUntil(this.destroy$),
        filter((profiles) => !!profiles), // Adjusted to ensure it always emits if data is there
        timeout(5000),
        catchError((error) => {
          console.error("Timeout or error waiting for profiles", error);
          // Retry logic or other fallback can be implemented here
          return of([]); // Ensure it returns to prevent hanging subscriptions
        }),
        take(1),
        takeUntil(this.destroy$) // Added as an extra precaution
      );
  }

  handleResult(result?: { [key: string]: DynamicNode[] }): void {
    if (!result) {
      console.error("Received undefined or null result.");
      return;
    }

    Object.entries(result).forEach(([id, dynamicNodes]) => {
      this.updateDataSource(id, dynamicNodes);
    });
  }

  private updateDataSource(id: string, dynamicNodes: DynamicNode[]): void {
    this.dataSourceTable[id] = dynamicNodes; // This is the critical line

    let dataSource = this.treeDataSources.get(id);
    if (!dataSource) {
      dataSource = this.createDataSource(id);
    }

    // Update the data source's data
    dataSource.value.data = dynamicNodes;
    dataSource.next(dataSource.value);
  }

  private createDataSource(
    id: string
  ): BehaviorSubject<
    MatTreeFlatDataSource<DynamicNode, DynamicFlatNode, DynamicFlatNode>
  > {
    const treeControl = new FlatTreeControl<DynamicFlatNode>(
      (node) => node.level,
      (node) => node.expandable
    );
    this.treeControls[id] = treeControl;
    const treeFlattener = new MatTreeFlattener<DynamicNode, DynamicFlatNode>(
      this.transformer,
      (node) => node.level,
      (node) => node.expandable,
      (node) => node.children
    );
    const dataSource = new BehaviorSubject(
      new MatTreeFlatDataSource(treeControl, treeFlattener)
    );
    this.treeDataSources.set(id, dataSource);
    return dataSource;
  }

  loadPerfis() {
    if (this.selectedSistemaId !== "") {
      this.store.dispatch(
        loadPerfisBySistema({
          sistemaId: this.selectedSistemaId,
        })
      );
    } else {
      ToastrFunctions.showError(
        this.toastr,
        "Por favor, selecione um sistema para os perfis"
      );
    }
  }

  onSelectChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedOption = selectElement.options[selectElement.selectedIndex];

    // Check if selectedOption is defined before accessing its text property
    const selectedLabel = selectedOption ? selectedOption.text : null;

    if (selectedLabel === Constants.PERFIL_GESTOR_CADASTROS) {
      this.isGestorCadastroSelected = true;
    } else {
      this.isGestorCadastroSelected = false;
    }
    this.cnpjs = [];
  }

  trackByCompanyFn(index: number, company: any): number {
    return company.id; // Assuming 'id' is the unique identifier for each company
  }

  onDeleteNode(usuarioRepresentanteId: string, node: DynamicFlatNode) {
    // Collect all IDs that need deletion, including descendants if any
    const idsToDelete = new Set<string>();
    if (node.expandable) {
      const descendants =
        this.treeControls[usuarioRepresentanteId]?.getDescendants(node);
      descendants.forEach((descendant) => idsToDelete.add(descendant.id));
    } else {
      idsToDelete.add(node.id);
    }

    if (idsToDelete.size > 0) {
      // Filter out the nodes from the dataSourceTable
      const existingNodes = this.dataSourceTable[usuarioRepresentanteId];
      const filteredNodes = this.filterNodes(existingNodes, idsToDelete);

      // Update the dataSource with the new filtered nodes
      this.updateDataSource(usuarioRepresentanteId, filteredNodes);

      // Perform deletion operation (e.g., API call or local update)
      this.deletaPermissao(usuarioRepresentanteId, Array.from(idsToDelete));
    }
  }

  private filterNodes(
    nodes: DynamicNode[],
    idsToDelete: Set<string>
  ): DynamicNode[] {
    // Recursively filter out nodes that need to be deleted, and remove expandable nodes if their children are empty
    return nodes.filter((node) => {
      // First, filter children if node has children
      if (node.children && node.children.length > 0) {
        node.children = this.filterNodes(node.children, idsToDelete);
        // If node is expandable and has no remaining children, it should be removed
        return !(node.hasChildren && node.children.length === 0);
      }
      // If node has no children, it stays unless its ID is in idsToDelete
      return !idsToDelete.has(node.id);
    });
  }

  private deletaPermissao(
    usuarioRepresentanteId: string,
    idsPerfil: string[]
  ): void {
    this.store.dispatch(
      deletePermissionsByUsuarioRepresentanteId({
        idUsuarioRepresentante: usuarioRepresentanteId,
        perfisIds: idsPerfil,
      })
    );
  }

  openDialogExclusao(
    usuarioRepresentanteId: string,
    node: DynamicFlatNode
  ): void {
    const dialogRef = this.dialog.open(DialogComponent, {
      width: "400px",
      data: { usuarioRepresentanteId, node },
    });

    dialogRef.afterClosed().subscribe({
      next: (result) => {
        if (result === true) {
          this.onDeleteNode(usuarioRepresentanteId, node);
        }
      },
      error: (err) =>
        console.error("Error occurred while closing the dialog:", err),
    });
  }

  insereSistemaPerfisUsuarioLote() {
    if (this.isGestorCadastroSelected) {
      if (this.cnpjs.length === 0) {
        ToastrFunctions.showWarning(
          this.toastr,
          "Seleciona ao menos uma pessoa jurídica para atribuir o gestor de cadastro."
        );
        return;
      }
    }

    const cnpjIds = this.isGestorCadastroSelected
      ? this.cnpjs.map((cnpj) => cnpj.id as string)
      : [];

    this.store.dispatch(
      insertSystemProfilesBatch({
        idPerfil: this.selectedPerfilId,
        cnpjsOrigem: cnpjIds,
      })
    );

    this.selectedPerfilId = "";
    this.selectedSistemaId = "";
    this.cnpjs = [];
    this.isGestorCadastroSelected = false;
  }

  atualizaDataExpiracaoLote() {
    let dataExpiracao = null;

    if (this.dtExpiracao) {
      const expirationDate = this.dtExpiracao;
      const today = new Date();

      // Set the time to 00:00:00 to ignore the time part in comparison
      today.setHours(0, 0, 0, 0);
      expirationDate.setHours(0, 0, 0, 0);

      if (expirationDate < today) {
        ToastrFunctions.showWarning(
          this.toastr,
          "Data de inativação não pode ser menor que a de hoje"
        );
        return; // Exit the function if the date is invalid
      }

      // Convert back to ISO string (YYYY-MM-DD format) for dispatching
      dataExpiracao = expirationDate.toISOString().substring(0, 10);
    }

    this.store.dispatch(
      updateExpirationDateBatch({
        dtExpiracao: dataExpiracao,
      })
    );
  }

  ativaInativaColaboradorLote(event: MatSlideToggleChange) {
    // Check if the toggle is disabled before proceeding
    if (event.source.disabled) {
      return; // Do nothing if the toggle is disabled
    }

    this.store.dispatch(
      toggleUserBlockBatchStatus({
        active: this.active,
      })
    );
  }

  unsubscribeAll(): void {
    this.treeDataSources.forEach((dataSource) => dataSource.unsubscribe());
    this.treeDataSources.clear(); // Clear the map of subscriptions
  }

  ngOnDestroy(): void {
    this.unsubscribeAll();
    this.dataSubscription.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }
}
