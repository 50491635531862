import { createAction, props } from "@ngrx/store";
import { ColaboradorCustom } from "../model/colaboradorcustom";
import { HttpErrorResponse } from "@angular/common/http";
import { ColaboradorInternoCustom } from "../model/colaboradorinternocustom";
import { ColaboradorSerpro } from "../model/colaboradorserpro";

export const loadAllEmployees = createAction(
  "[Employee] Load All Employees",
  props<{ idPessoaJuridica: string | null }>()
);

export const loadAllEmployeesSuccess = createAction(
  "[Employee] Load All Employees Success",
  props<{ employees: ColaboradorCustom[] }>()
);

export const loadAllEmployeesFailure = createAction(
  "[Employee] Load All Employees Failure",
  props<{ error: any }>()
);

// Action for selecting employees by noPessoaFisica
export const chooseEmployees = createAction(
  "[Employee] Choose Employees",
  props<{ employees: ColaboradorCustom[]; noPessoaFisica: string }>()
);

export const chooseEmployeesSuccess = createAction(
  "[Employee] Choose Employees Success",
  props<{ filteredEmployees: ColaboradorCustom[] }>()
);

export const chooseEmployeesFailure = createAction(
  "[Employee] Choose Employees Failure",
  props<{ error: string }>()
);

// Action for selecting employees by noPessoaFisica
export const editEmployee = createAction(
  "[Employee] Select Employee",
  props<{ idPessoaJuridica: string; idUsuarioRepresentante: string }>()
);

export const editEmployeeSuccess = createAction(
  "[Employee] Select Employee Success",
  props<{ employee: ColaboradorCustom }>()
);

export const editEmployeeFailure = createAction(
  "[Employee] Select Employee Failure",
  props<{ error: any }>()
);

export const editInternalEmployee = createAction(
  "[Employee] Select Internal Employee",
  props<{ idUsuarioRepresentante: string; }>()
);

export const editInternalEmployeeSuccess = createAction(
  "[Employee] Select Internal Employee Success",
  props<{ employee: ColaboradorInternoCustom }>()
);

export const editInternalEmployeeFailure = createAction(
  "[Employee] Select Internal Employee Failure",
  props<{ error: any }>()
);

// Update expiration date actions
export const updateExpirationDate = createAction(
  "[Employee] Update Expiration Date",
  props<{ dtExpiracao: string | null }>()
);
export const updateExpirationDateSuccess = createAction(
  "[Employee] Update Expiration Date Success",
  props<{ result: boolean }>()
);
export const updateExpirationDateFailure = createAction(
  "[Employee] Update Expiration Date Failure",
  props<{ error: any }>()
);

// Toggle user block status
export const toggleUserBlockStatus = createAction(
  "[Employee] Toggle User Block Status"
);
export const toggleUserBlockStatusSuccess = createAction(
  "[Employee] Toggle User Block Status Success",
  props<{ isUpdated: boolean }>()
);
export const toggleUserBlockStatusFailure = createAction(
  "[Employee] Toggle User Block Status Failure",
  props<{ error: any }>()
);

// Action definitions for inserting system profiles
export const insertSystemProfiles = createAction(
  "[Employee] Insert System Profiles",
  props<{
    idSistema: string;
    idsPerfis: string[];
    cnpjsOrigem: string[];
    cpf: string;
  }>()
);
export const insertSystemProfilesSuccess = createAction(
  "[Employee] Insert System Profiles Success",
  props<{ successMessage: string }>()
);
export const insertSystemProfilesFailure = createAction(
  "[Employee] Insert System Profiles Failure",
  props<{ error: any }>()
);

// Action definitions for deleting permissions
export const deletePermissions = createAction(
  "[Employee] Delete Permissions",
  props<{ perfisIds: string[]; }>()
);
export const deletePermissionsSuccess = createAction(
  "[Employee] Delete Permissions Success",
  props<{ successMessage: string }>()
);
export const deletePermissionsFailure = createAction(
  "[Employee] Delete Permissions Failure",
  props<{ error: any }>()
);

export const toggleAllEmployees = createAction(
  "[Employee] Toggle All",
  props<{ isSelected: boolean }>()
);
export const toggleEmployee = createAction(
  "[Employee] Toggle Employee",
  props<{ idUsuarioRepresentante: string; isSelected: boolean }>()
);

// Action for selecting employees by to batch
export const editEmployees = createAction(
  "[Employee] Select Employees",
  props<{ employees: ColaboradorCustom[], isPerfilGestorAnvisa: boolean }>()
);

// Action definitions for inserting system profiles
export const insertSystemProfilesBatch = createAction(
  "[Employee] Insert System Profiles in Batch",
  props<{
    idPerfil: string;
    cnpjsOrigem: string[];
  }>()
);
export const insertSystemProfilesBatchSuccess = createAction(
  "[Employee] Insert System Profiles in Batch Success",
  props<{ successMessage: string }>()
);
export const insertSystemProfilesBatchFailure = createAction(
  "[Employee] Insert System Profiles in Batch Failure",
  props<{ error: any }>()
);


// Action definitions for deleting permissions using usuario representante id
export const deletePermissionsByUsuarioRepresentanteId = createAction(
  "[Employee] Delete Permissions By Usuario Representante Id",
  props<{ idUsuarioRepresentante: string; perfisIds: string[]; }>()
);
export const deletePermissionsByUsuarioRepresentanteIdSuccess = createAction(
  "[Employee] Delete Permissions By Usuario Representante Id Success",
  props<{ successMessage: string }>()
);
export const deletePermissionsByUsuarioRepresentanteIdFailure = createAction(
  "[Employee] Delete Permissions By Usuario Representante Id Failure",
  props<{ error: any }>()
);

// Update expiration date batch actions
export const updateExpirationDateBatch = createAction(
  "[Employee] Update Expiration Date Batch",
  props<{ dtExpiracao: string | null }>()
);
export const updateExpirationDateBatchSuccess = createAction(
  "[Employee] Update Expiration Date Batch Success",
  props<{ dtExpiracao: string | null, result: boolean }>()
);
export const updateExpirationDateBatchFailure = createAction(
  "[Employee] Update Expiration Date Batch Failure",
  props<{ error: any }>()
);

// Toggle user block batch status
export const toggleUserBlockBatchStatus = createAction(
  "[Employee] Toggle User Block Status Batch",
  props<{ active: boolean }>()
);
export const toggleUserBlockStatusBatchSuccess = createAction(
  "[Employee] Toggle User Block Status Batch Success",
  props<{ active: boolean,isUpdated: boolean }>()
);
export const toggleUserBlockStatusBatchFailure = createAction(
  "[Employee] Toggle User Block Status Batch Failure",
  props<{ error: any }>()
);

export const setFiltered = createAction(
  '[Employee] Set Filtered',
  props<{ filtered: boolean }>()
);

export const verificarValidadeCPF = createAction(
  '[Employee] Verificar Validade CPF',
  props<{ cpf: string }>()
);

export const verificarValidadeCPFSuccess = createAction(
  '[Employee] Verificar Validade CPF Success',
  props<{ colaborador: ColaboradorSerpro }>()
);

export const verificarValidadeCPFFailure = createAction(
  '[Employee] Verificar Validade CPF Failure',
  props<{ error: HttpErrorResponse }>()
);

export const insereColaborador = createAction(
  '[Employee] Insere Colaborador',
  props<{ pessoaJuridicaId: string, colaborador: ColaboradorSerpro }>()
);

export const insereColaboradorSuccess = createAction(
  '[Employee] Insere Colaborador Success',
  props<{ message: string }>()
);

export const insereColaboradorFailure = createAction(
  '[Employee] Insere Colaborador Failure',
  props<{ error: HttpErrorResponse }>()
);

export const insereColaboradorInterno = createAction(
  '[Employee] Insere Colaborador Interno',
  props<{ colaborador: ColaboradorSerpro }>()
);

export const insereColaboradorInternoSuccess = createAction(
  '[Employee] Insere Colaborador Interno Success',
  props<{ message: string }>()
);

export const insereColaboradorInternoFailure = createAction(
  '[Employee] Insere Colaborador Interno Failure',
  props<{ error: HttpErrorResponse }>()
);

export const setUpdatedEmployee = createAction(
  '[Employee] Set Updated',
  props<{ updated: boolean }>()
);

export const resetColaboradorSerpro = createAction(
  '[Employee] Reset Colaborador Serpro'
);

export const resetAllEmployees = createAction('[Employee] Reset All Employees');

export const clearEditEmployees = createAction("[Employee] Clear Edit Employees State");
