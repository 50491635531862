// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `mat-card {
  font-family: "Arial", sans-serif;
  margin: 20px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

mat-card-title {
  font-size: 24px;
  margin-bottom: 15px;
  color: #333;
}

mat-card-content {
  margin-bottom: 20px;
}

mat-form-field {
  width: 100%;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #ddd;
}

button.mat-button {
  margin-right: 8px;
}

button.mat-button:hover {
  opacity: 0.8;
}

mat-card-actions button {
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px 20px;
  margin-top: 10px;
}

/* Add media queries for responsiveness */
@media (max-width: 768px) {
  mat-form-field, table, button.mat-button {
    width: 100%;
    margin-top: 10px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/integrador/integrador.component.scss"],"names":[],"mappings":"AAAA;EACI,gCAAA;EACA,YAAA;EACA,aAAA;EACA,wCAAA;AACJ;;AAEE;EACE,eAAA;EACA,mBAAA;EACA,WAAA;AACJ;;AAEE;EACE,mBAAA;AACJ;;AAEE;EACE,WAAA;EACA,mBAAA;AACJ;;AAEE;EACE,WAAA;EACA,yBAAA;AACJ;;AAEE;EACE,gBAAA;EACA,YAAA;AACJ;;AAEE;EACE,yBAAA;AACJ;;AAEE;EACE,sBAAA;AACJ;;AAEE;EACE,iBAAA;AACJ;;AAEE;EACE,YAAA;AACJ;;AAEE;EACE,eAAA;EACA,yBAAA;EACA,kBAAA;EACA,gBAAA;AACJ;;AAEE,yCAAA;AACA;EACE;IACE,WAAA;IACA,gBAAA;EACJ;AACF","sourcesContent":["mat-card {\n    font-family: 'Arial', sans-serif;\n    margin: 20px;\n    padding: 20px;\n    box-shadow: 0 4px 8px rgba(0,0,0,0.1);\n  }\n  \n  mat-card-title {\n    font-size: 24px;\n    margin-bottom: 15px;\n    color: #333;\n  }\n  \n  mat-card-content {\n    margin-bottom: 20px;\n  }\n  \n  mat-form-field {\n    width: 100%;\n    margin-bottom: 20px;\n  }\n  \n  table {\n    width: 100%;\n    border-collapse: collapse;\n  }\n  \n  th, td {\n    text-align: left;\n    padding: 8px;\n  }\n  \n  tr:nth-child(even) {\n    background-color: #f2f2f2;\n  }\n  \n  tr:hover {\n    background-color: #ddd;\n  }\n  \n  button.mat-button {\n    margin-right: 8px;\n  }\n  \n  button.mat-button:hover {\n    opacity: 0.8;\n  }\n  \n  mat-card-actions button {\n    font-size: 16px;\n    text-transform: uppercase;\n    padding: 10px 20px;\n    margin-top: 10px;\n  }\n  \n  /* Add media queries for responsiveness */\n  @media (max-width: 768px) {\n    mat-form-field, table, button.mat-button {\n      width: 100%;\n      margin-top: 10px;\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
