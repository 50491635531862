// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-custom-1 {
  background-color: #F7F7F7;
  font-weight: 500;
  font-size: 14px;
  color: #333333;
  padding: 10px;
}

.custom-width {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/gestao-perfil-colab-inter/dialog-add-perfil-colab/dialog-add-perfil-colab.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,gBAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;AACJ;;AACA;EACI,WAAA;AAEJ","sourcesContent":[".card-custom-1 {\n    background-color: #F7F7F7;\n    font-weight: 500;\n    font-size: 14px;\n    color: #333333;\n    padding: 10px;\n}\n.custom-width {\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
