// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
ul li {
  display: inline;
}
ul li:before {
  content: ">";
  margin: 0 6px;
}
ul li:first-child:before {
  content: "";
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/components/breadcrumb/breadcrumb.component.scss"],"names":[],"mappings":"AACI;EACE,gBAAA;EACA,SAAA;EACA,UAAA;AAAN;AAIM;EACE,eAAA;AAFR;AAKM;EACE,YAAA;EACA,aAAA;AAHR;AAMM;EACE,WAAA;EACA,SAAA;AAJR","sourcesContent":["ul {\n    & {\n      list-style: none;\n      margin: 0;\n      padding: 0;\n    }\n  \n    li {\n      & {\n        display: inline;\n      }\n  \n      &:before {\n        content: \">\";\n        margin: 0 6px;\n      }\n  \n      &:first-child:before {\n        content: \"\";\n        margin: 0;\n      }\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
