import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { from, of } from "rxjs";
import { catchError, map, mergeMap, withLatestFrom } from "rxjs/operators";
import * as EmployeeActions from "../actions/employee.action";
import { ColaboradorService } from "../service/colaborador.service";
import { Store } from "@ngrx/store";
import { SistemaPerfisColaboradorRequest } from "../model/sistemaperfiscolaboradorrequest.model";
import {
  clearColaboradorProfilesNodeByUsuarioRepresentanteId,
  clearNodeProfiles,
  loadPerfisByUsuarioRepresentante,
} from "../actions/perfil.actions";
import {
  loadSistemaByUser,
  loadSistemaByUsuarioRepresentanteId,
} from "../actions/sistema.actions";
import { AppState } from "../interfaces/app-state.interface";
import { ToastrService } from "ngx-toastr";
import { ToastrFunctions } from "../util/toastr.functions";
import { ColaboradorCustom } from "../model/colaboradorcustom";
import { SerproService } from "../service/serpro.service";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class EmployeeEffects {
  showSpinner = false;

  constructor(
    private actions$: Actions,
    private colaboradorService: ColaboradorService,
    private store: Store<AppState>, // Inject the store to access the current state
    private toastr: ToastrService,
    private serproService: SerproService
  ) {}

  loadAllEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.loadAllEmployees),
      mergeMap((action) =>
        this.colaboradorService
          .findAllUsuariosByPessoaJuridicaId(action.idPessoaJuridica)
          .pipe(
            map((employees) =>
              EmployeeActions.loadAllEmployeesSuccess({ employees })
            ),
            catchError((error) =>
              of(
                EmployeeActions.loadAllEmployeesFailure({
                  error: "Failed to fetch employees",
                })
              )
            ) // Simplified error handling for demonstration
          )
      )
    )
  );

  chooseEmployees$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.chooseEmployees),
      mergeMap((action) =>
        of(
          action.employees.filter(
            (employee) =>
              employee.noPessoaFisica.toLowerCase().includes(action.noPessoaFisica.toLowerCase())
          )
        ).pipe(
          map((filteredEmployees: ColaboradorCustom[]) =>
            EmployeeActions.chooseEmployeesSuccess({ filteredEmployees })
          ),
          catchError((error) =>
            of(EmployeeActions.chooseEmployeesFailure({ error: error.message || 'Unknown error' }))
          )
        )
      )
    )
  );

  selectEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.editEmployee),
      mergeMap((action) =>
        this.colaboradorService
          .findUsuarioByPessoaJuridicaIdAndIdUsuarioRepresentante(
            action.idPessoaJuridica,
            action.idUsuarioRepresentante
          )
          .pipe(
            map((employee) =>
              EmployeeActions.editEmployeeSuccess({ employee })
            ),
            catchError((error) =>
              of(
                EmployeeActions.editEmployeeFailure({
                  error: error.message || "Unknown error",
                })
              )
            )
          )
      )
    )
  );

  selectInternalEmployee$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.editInternalEmployee),
      mergeMap((action) =>
        this.colaboradorService
          .findUsuarioInternoByIdUsuarioRepresentante(
            action.idUsuarioRepresentante
          )
          .pipe(
            map((employee) =>
              EmployeeActions.editInternalEmployeeSuccess({ employee })
            ),
            catchError((error) =>
              of(
                EmployeeActions.editInternalEmployeeFailure({
                  error: error.message || "Unknown error",
                })
              )
            )
          )
      )
    )
  );

  updateExpirationDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.updateExpirationDate),
      withLatestFrom(
        this.store.select((state) => state.employees.selectedEmployee)
      ), // Ajuste o caminho conforme necessário
      mergeMap(([action, selectedEmployee]) => {
        if (!selectedEmployee) {
          ToastrFunctions.showError(
            this.toastr,
            "Nenhum funcionário selecionado disponível"
          );
          return of(
            EmployeeActions.updateExpirationDateFailure({
              error: "Nenhum funcionário selecionado disponível",
            })
          );
        }
        return this.colaboradorService
          .atualizaDtExpiracao(
            selectedEmployee.idUsuarioRepresentante,
            action.dtExpiracao
          )
          .pipe(
            map((result) => {
              const successMessage = action.dtExpiracao
                ? "Data de Inativação atualizada com sucesso."
                : "Data de Inativação removida com sucesso.";
              ToastrFunctions.showInfo(this.toastr, successMessage);
              return EmployeeActions.updateExpirationDateSuccess({ result });
            }),
            catchError((error) => {
              ToastrFunctions.showError(
                this.toastr,
                "Algum erro ocorreu ao atualizar a data de Inativação."
              );
              return of(
                EmployeeActions.updateExpirationDateFailure({
                  error: error.message || "Ocorreu um erro desconhecido",
                })
              );
            })
          );
      })
    )
  );
  

  toggleUserBlockStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.toggleUserBlockStatus),
      withLatestFrom(
        this.store.select((state) => state.employees.selectedEmployee)
      ), // Adjust path as necessary to access selectedEmployee
      mergeMap(([, selectedEmployee]) => {
        if (!selectedEmployee) {
          ToastrFunctions.showError(
            this.toastr,
            "Nenhum funcionário selecionado encontrado"
          );
          return of(
            EmployeeActions.toggleUserBlockStatusFailure({
              error: "Nenhum funcionário selecionado encontrado",
            })
          );
        }

        return this.colaboradorService.updateUsuario(selectedEmployee).pipe(
          map((isUpdated) => {
            ToastrFunctions.showInfo(
              this.toastr,
              "Status de bloqueio do usuário atualizado com sucesso."
            );
            return EmployeeActions.toggleUserBlockStatusSuccess({ isUpdated });
          }),
          catchError((error) => {
            ToastrFunctions.showError(
              this.toastr,
              "Algum erro ocorreu ao atualizar o status de bloqueio do usuário."
            );
            return of(
              EmployeeActions.toggleUserBlockStatusFailure({
                error:
                  error.message ||
                  "Ocorreu um erro desconhecido ao alterar o status",
              })
            );
          })
        );
      })
    )
  );

  insertSystemProfiles$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.insertSystemProfiles),
      withLatestFrom(
        this.store.select((state) => state.employees.selectedEmployee)
      ),
      mergeMap(([action, selectedEmployee]) => {
        if (!selectedEmployee) {
          ToastrFunctions.showError(
            this.toastr,
            "Nenhum funcionário selecionado encontrado"
          );
          return of(
            EmployeeActions.insertSystemProfilesFailure({
              error: "Nenhum funcionário selecionado encontrado",
            })
          );
        }

        const request = new SistemaPerfisColaboradorRequest(
          selectedEmployee.idUsuarioRepresentante,
          action.idSistema,
          action.idsPerfis,
          action.cnpjsOrigem,
          action.cpf
        );

        return this.colaboradorService.insereSistemaPerfilUsuario(request).pipe(
          map((successMessage) => {
            this.store.dispatch(
              loadPerfisByUsuarioRepresentante({
                idUsuarioRepresentante: selectedEmployee.idUsuarioRepresentante,
              })
            );
            this.store.dispatch(
              loadSistemaByUser({
                idUsuarioRepresentante: selectedEmployee.idUsuarioRepresentante,
              })
            );
            this.store.dispatch(clearNodeProfiles());

            ToastrFunctions.showInfo(
              this.toastr,
              "Perfis do sistema inseridos com sucesso."
            );
            return EmployeeActions.insertSystemProfilesSuccess({
              successMessage,
            });
          }),
          catchError((error) => {
            ToastrFunctions.showError(
              this.toastr,
              "Algum erro ocorreu ao inserir os perfis do sistema."
            );
            return of(
              EmployeeActions.insertSystemProfilesFailure({
                error: error.message || "Ocorreu um erro desconhecido",
              })
            );
          })
        );
      })
    )
  );

  deletePermissions$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.deletePermissions),
      withLatestFrom(
        this.store.select((state) => state.employees.selectedEmployee)
      ),
      mergeMap(([action, selectedEmployee]) => {
        if (!selectedEmployee) {
          ToastrFunctions.showError(
            this.toastr,
            "Nenhum funcionário selecionado disponível"
          );
          return of(
            EmployeeActions.deletePermissionsFailure({
              error: "Nenhum funcionário selecionado disponível",
            })
          );
        }

        return this.colaboradorService
          .deletePermissao(
            selectedEmployee.idUsuarioRepresentante,
            action.perfisIds
          )
          .pipe(
            map((successMessage) => {
              this.store.dispatch(
                loadPerfisByUsuarioRepresentante({
                  idUsuarioRepresentante:
                    selectedEmployee.idUsuarioRepresentante,
                })
              );
              this.store.dispatch(
                loadSistemaByUser({
                  idUsuarioRepresentante:
                    selectedEmployee.idUsuarioRepresentante,
                })
              );
              this.store.dispatch(clearNodeProfiles());

              ToastrFunctions.showInfo(
                this.toastr,
                "Permissões excluídas com sucesso."
              );
              return EmployeeActions.deletePermissionsSuccess({
                successMessage,
              });
            }),
            catchError((error) => {
              ToastrFunctions.showError(
                this.toastr,
                "Algum erro ocorreu ao excluir as permissões."
              );
              return of(
                EmployeeActions.deletePermissionsFailure({
                  error: error.message || "Ocorreu um erro desconhecido",
                })
              );
            })
          );
      })
    )
  );

  insertSystemProfilesBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.insertSystemProfilesBatch),
      withLatestFrom(
        this.store.select((state) => state.employees.selectedEmployees)
      ),
      mergeMap(([action, selectedEmployees]) => {
        if (!selectedEmployees || selectedEmployees.length === 0) {
          ToastrFunctions.showError(
            this.toastr,
            "Nenhum funcionário selecionado encontrado"
          );
          return of(
            EmployeeActions.insertSystemProfilesFailure({
              error: "Nenhum funcionário selecionado encontrado",
            })
          );
        }
        const idsUsuarioRepresentante = selectedEmployees.map(
          (colaborador) => colaborador.idUsuarioRepresentante
        );

        return this.colaboradorService
          .adicionaPermissaoColaboradorLote(
            idsUsuarioRepresentante,
            action.idPerfil,
            action.cnpjsOrigem
          )
          .pipe(
            map((successMessage) => {
              // Dispatch actions for each selected employee
              selectedEmployees.forEach((selectedEmployee) => {
                this.store.dispatch(
                  loadSistemaByUsuarioRepresentanteId({
                    idUsuarioRepresentante:
                      selectedEmployee.idUsuarioRepresentante,
                  })
                );
                this.store.dispatch(
                  clearColaboradorProfilesNodeByUsuarioRepresentanteId({
                    idUsuarioRepresentante:
                      selectedEmployee.idUsuarioRepresentante,
                  })
                );
              });

              ToastrFunctions.showInfo(
                this.toastr,
                "Perfis do sistema inseridos em lote com sucesso."
              );
              return EmployeeActions.insertSystemProfilesBatchSuccess({
                successMessage,
              });
            }),
            catchError((error) => {
              ToastrFunctions.showError(
                this.toastr,
                "Algum erro ocorreu ao inserir os perfis do sistema em lote."
              );
              return of(
                EmployeeActions.insertSystemProfilesBatchFailure({
                  error: error.message || "Ocorreu um erro desconhecido",
                })
              );
            })
          );
      })
    )
  );

  deletePermissionsByUsuarioRepresentanteId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.deletePermissionsByUsuarioRepresentanteId),
      mergeMap((action) => {
        return this.colaboradorService
          .deletePermissao(
            action.idUsuarioRepresentante,
            action.perfisIds
          )
          .pipe(
            map((successMessage) => {
              this.store.dispatch(
                loadSistemaByUsuarioRepresentanteId({
                  idUsuarioRepresentante: action.idUsuarioRepresentante,
                })
              );
              this.store.dispatch(
                clearColaboradorProfilesNodeByUsuarioRepresentanteId({
                  idUsuarioRepresentante: action.idUsuarioRepresentante,
                })
              );

              ToastrFunctions.showInfo(
                this.toastr,
                "Permissões excluídas com sucesso."
              );
              return EmployeeActions.deletePermissionsByUsuarioRepresentanteIdSuccess(
                {
                  successMessage,
                }
              );
            }),
            catchError((error) => {
              ToastrFunctions.showError(
                this.toastr,
                "Algum erro ocorreu ao excluir as permissões."
              );
              return of(
                EmployeeActions.deletePermissionsByUsuarioRepresentanteIdFailure(
                  {
                    error: error.message || "Ocorreu um erro desconhecido",
                  }
                )
              );
            })
          );
      })
    )
  );

  updateExpirationDateBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.updateExpirationDateBatch),
      withLatestFrom(
        this.store.select((state) => state.employees.selectedEmployees)
      ),
      mergeMap(([action, selectedEmployees]) => {
        if (!selectedEmployees || selectedEmployees.length === 0) {
          ToastrFunctions.showError(
            this.toastr,
            "Nenhum funcionário selecionado disponível"
          );
          return of(
            EmployeeActions.updateExpirationDateFailure({
              error: "Nenhum funcionário selecionado disponível",
            })
          );
        }
  
        const idUsuariosRepresentantes = selectedEmployees.map(
          (employee) => employee.idUsuarioRepresentante
        );
  
        return this.colaboradorService
          .atualizaDtExpiracaoEmLote(idUsuariosRepresentantes, action.dtExpiracao)
          .pipe(
            map((result) => {
              const successMessage = action.dtExpiracao
                ? `Data de Inativação dos funcionários atualizada com sucesso.`
                : `Data de Inativação dos funcionários removida com sucesso.`;
              ToastrFunctions.showInfo(this.toastr, successMessage);
              return EmployeeActions.updateExpirationDateBatchSuccess({
                dtExpiracao: action.dtExpiracao,
                result,
              });
            }),
            catchError((error) => {
              const errorMessage = action.dtExpiracao
                ? `Algum erro ocorreu ao atualizar a data de Inativação dos funcionários.`
                : `Algum erro ocorreu ao remover a data de Inativação dos funcionários.`;
              ToastrFunctions.showError(this.toastr, errorMessage);
              return of(
                EmployeeActions.updateExpirationDateBatchFailure({
                  error: error.message || "Ocorreu um erro desconhecido",
                })
              );
            })
          );
      })
    )
  );

  toggleUserBlockStatusBatch$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.toggleUserBlockBatchStatus),
      withLatestFrom(
        this.store.select((state) => state.employees.selectedEmployees)
      ),
      mergeMap(([action, selectedEmployees]) => {
        if (!selectedEmployees || selectedEmployees.length === 0) {
          ToastrFunctions.showError(
            this.toastr,
            "Nenhum funcionário selecionado encontrado"
          );
          return of(
            EmployeeActions.toggleUserBlockStatusBatchFailure({
              error: "Nenhum funcionário selecionado encontrado",
            })
          );
        }
  
        // Filter employees who are currently in the opposite state of the action's 'active' flag
        const employeesToToggle = selectedEmployees.filter(
          (selectedEmployee) =>
            selectedEmployee.stBloqueado === (action.active ? "S" : "N")
        );
  
        if (employeesToToggle.length === 0) {
          ToastrFunctions.showError(
            this.toastr,
            `Nenhum funcionário encontrado que pudesse ser ${
              action.active ? "ativado" : "desativado"
            }.`
          );
          return of(
            EmployeeActions.toggleUserBlockStatusBatchFailure({
              error: `Nenhum funcionário encontrado que pudesse ser ${
                action.active ? "ativado" : "desativado"
              }.`,
            })
          );
        }
  
        return this.colaboradorService
          .updateUsuariosEmLote(
            employeesToToggle.map((employee) => ({
              ...employee,
              stBloqueado: action.active ? "N" : "S", // Assuming 'S' means blocked and 'N' means not blocked
            }))
          )
          .pipe(
            map((result) => {
              ToastrFunctions.showInfo(
                this.toastr,
                `Status de bloqueio dos funcionários atualizados com sucesso.`
              );
              return EmployeeActions.toggleUserBlockStatusBatchSuccess({
                active: action.active,
                isUpdated: result,
              });
            }),
            catchError((error) => {
              ToastrFunctions.showError(
                this.toastr,
                `Algum erro ocorreu ao ${
                  action.active ? "ativar" : "desativar"
                } os funcionários.`
              );
              return of(
                EmployeeActions.toggleUserBlockStatusBatchFailure({
                  error:
                    error.message ||
                    "Ocorreu um erro desconhecido ao alterar o status",
                })
              );
            })
          );
      })
    )
  );
  

  verificarValidadeCPF$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.verificarValidadeCPF),
      mergeMap(action =>
        this.serproService.verificarValidadeCPF(action.cpf).pipe(
          map(colaborador => {
            if (colaborador) {
              ToastrFunctions.showInfo(this.toastr, "CPF verificado com sucesso.");
              return EmployeeActions.verificarValidadeCPFSuccess({ colaborador });
            } else {
              ToastrFunctions.showWarning(this.toastr, "CPF não encontrado.");
              return EmployeeActions.verificarValidadeCPFFailure({ error: new HttpErrorResponse({error: "CPF não encontrado."}) });
            }
          }),
          catchError(error => {
            ToastrFunctions.showError(this.toastr, "Algum erro ocorreu ao verificar o CPF.");
            return of(EmployeeActions.verificarValidadeCPFFailure({ error }));
          })
        )
      )
    )
  );

  insereColaborador$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.insereColaborador),
      mergeMap(action =>
        this.colaboradorService.insereColaborador(action.pessoaJuridicaId, action.colaborador).pipe(
          map(message => {
            ToastrFunctions.showInfo(this.toastr, message);
            return EmployeeActions.insereColaboradorSuccess({ message });
          }),
          catchError(error => {
            let errorMessage = "Erro ao inserir colaborador.";
            if (error.status === 400) {
              errorMessage += ` Detalhes: ${error.error}`;
              ToastrFunctions.showWarning(this.toastr, errorMessage);
            } else {
              ToastrFunctions.showError(this.toastr, errorMessage);
            }
            return of(EmployeeActions.insereColaboradorFailure({ error }));
          })
        )
      )
    )
  );

  insereColaboradorInterno$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EmployeeActions.insereColaboradorInterno),
      mergeMap(action =>
        this.colaboradorService.insereColaboradorInterno(action.colaborador).pipe(
          map(message => {
            ToastrFunctions.showInfo(this.toastr, message);            
            return EmployeeActions.insereColaboradorInternoSuccess({ message });
          }),
          catchError(error => {
            let errorMessage = "Erro ao inserir colaborador interno.";
            if (error.status === 400) {
              errorMessage += ` Detalhes: ${error.error}`;
              ToastrFunctions.showWarning(this.toastr, errorMessage);
            } else {
              ToastrFunctions.showError(this.toastr, errorMessage);
            }
            return of(EmployeeActions.insereColaboradorInternoFailure({ error }));
          })
        )
      )
    )
  );

}
